import { useTranslation } from "next-i18next";
import { FunctionComponent } from "react";

import styles from "./ErrorMessage.module.scss";

import CTAButton from "@components/generic/ctaButton";
import { EMPTY } from "@lib/helper/stringUtils";

interface IErrorMessageProps {
  message: string;
  onRetry?(): void;
  className?: string;
}

const ErrorMessage: FunctionComponent<IErrorMessageProps> = ({ message, onRetry, className }) => {
  const { t } = useTranslation("common");

  if (message === EMPTY) return <></>;

  return (
    <div className={`${styles["error-message"]} ${className}`}>
      <p>{message}</p>
      {!!onRetry && (
        <CTAButton onClick={onRetry} type="tertiary">
          {t("action.retry")}
        </CTAButton>
      )}
    </div>
  );
};

export default ErrorMessage;
