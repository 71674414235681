import unorm from "unorm";

export const regExpReplace = /[\u0300-\u036f]/g;

function propComparator<T extends { name: string }>(firstElem: string) {
  return (a: T, b: T): number => {
    const nameA = trimAndLower(a.name);
    const nameB = trimAndLower(b.name);

    if (nameA.startsWith(firstElem) && !nameB.startsWith(firstElem)) {
      return -1;
    }
    if (!nameA.startsWith(firstElem) && nameB.startsWith(firstElem)) {
      return 1;
    }
    if (nameA < nameB) {
      return -1;
    } else if (nameA > nameB) {
      return 1;
    } else {
      return 0;
    }
  };
}

export function trimLowerAndSplitText(text: string): Array<string> {
  return unorm.nfd(text.toLowerCase().replace(/\s+/g, " ").trim()).replace(regExpReplace, "").split(" ");
}

export function trimAndLower(text: string): string {
  return unorm.nfd(text.toLowerCase().replace(/\s+/g, " ").trim()).replace(regExpReplace, "");
}

export function getDelimiterParts(delimiter: string): string[] {
  let delimiterParts: string[] = trimLowerAndSplitText(delimiter);
  if (delimiterParts.some((text) => ["et", "and"].includes(text.toLowerCase()))) {
    delimiterParts.push("&");
  }
  delimiterParts = delimiterParts.filter((text) => !["et", "and"].includes(text.toLowerCase()));

  return delimiterParts;
}

export default function filterEntities<T extends { name: string }>(searchedText: string, entities: T[]): T[] {
  let filteredEntities: T[] = [];
  const filteredSearchedText = searchedText.trim().replace("’", "'");
  if (filteredSearchedText) {
    const listText = getDelimiterParts(filteredSearchedText);
    filteredEntities = entities
      .filter((entity) =>
        listText.every(
          (text) =>
            unorm.nfd(entity.name.toLowerCase()).replace(regExpReplace, "").includes(text) ||
            unorm
              .nfd(entity.name.toLowerCase())
              .replace(/[^\w\s]/gi, "")
              .includes(text)
        )
      )
      .sort(propComparator(trimAndLower(filteredSearchedText)));
  }

  return filteredEntities;
}
