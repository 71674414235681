import { AxiosError } from "axios";

const errorCodes = ["entity-not-found"] as const;
export type ClearFashionErrorCodes = typeof errorCodes[number];

const isClearFashionError = (code: unknown): code is ClearFashionErrorCodes => {
  return typeof code === "string" && errorCodes.includes(code as ClearFashionErrorCodes);
};

export class EntityNotFoundError extends AxiosError {
  constructor() {
    super();
    this.message = "Entity Not Found";
    this.code = "entity-not-found";
  }
}

export function extractError(error: AxiosError): AxiosError {
  console.log("error: ", error);
  if (isClearFashionError(error.code)) return error;
  let messageError = "api.generic";

  if (error.response) {
    switch (error.response.status) {
      case 401:
        messageError = "api.invalidToken";
        break;
      case 404:
        return new EntityNotFoundError();
      case 500:
        messageError = "api.serverError";
    }
  }
  return new AxiosError(messageError);
}
