import { FunctionComponent, ReactNode, useEffect, useState } from "react";

import styles from "./Loader.module.scss";

interface ILoaderProps {
  children: ReactNode;
  className?: string;
}

const Loader: FunctionComponent<ILoaderProps> = ({ children, className }) => {
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    setDisplay(false);
    setTimeout(() => {
      setDisplay(true);
    }, 300);
  }, []);

  return (
    <div className={`${styles["loader"]} ${className}`} style={{ opacity: display ? 1 : 0 }}>
      <div className={styles["loader__container"]}>{children}</div>
    </div>
  );
};

export default Loader;
