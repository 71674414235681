module.exports = {
  defaultTitle: "Clear Fashion",
  openGraph: {
    title: "Clear fashion - Faites parler les marques",
    description:
      "Le tiers-indépendant qui vous informe sur les pratiques des marques et leurs impacts. Recherchez une marque parmi les plus de 500 marques évaluées et découvrez l'évaluation Clear fashion sur 4 thématiques : environnement, d'humain, santé et d'animal. Téléchargez l'application pour profiter de davantage de fonctionnalités.",
    images: [
      {
        url: "https://cdn.clear-fashion.com/assets/logo.png",
        width: 400,
        height: 400,
        alt: "Clear Fashion Logo",
      },
    ],
    type: "website",
    site_name: "ClearFashion",
  },
  twitter: {
    site: "@clearfashionapp",
    cardType: "summary_large_image",
    handle: "@clearfashionapp",
  },
  facebook: {
    appId: "1692401884161573",
  },
  additionalLinkTags: [
    {
      rel: "apple-touch-icon",
      sizes: "57x57",
      href: "/icons/apple-icon-57x57.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "60x60",
      href: "/icons/apple-icon-60x60.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "72x72",
      href: "/icons/apple-icon-72x72.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "76x76",
      href: "/icons/apple-icon-76x76.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "114x114",
      href: "/icons/apple-icon-114x114.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "120x120",
      href: "/icons/apple-icon-120x120.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "144x144",
      href: "/icons/apple-icon-144x144.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "152x152",
      href: "/icons/apple-icon-152x152.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "180x180",
      href: "/icons/apple-icon-180x180.png",
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "192x192",
      href: "/icons/android-icon-192x192.png",
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "32x32",
      href: "/icons/favicon-32x32.png",
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "96x96",
      href: "/icons/favicon-96x96.png",
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "16x16",
      href: "/icons/favicon-16x16.png",
    },
  ],
  additionalMetaTags: [
    {
      name: "msapplication-TileColor",
      content: "#001e28",
    },
    {
      name: "msapplication-TileImage",
      content: "/icons/ms-icon-144x144.png",
    },
    {
      name: "theme-color",
      content: "#ffffff",
    },
  ],
};
