export const MOBILE_THRESHOLD = 803;

export const createArrayFromOneToN = (n: number): number[] => {
  return Array.from({ length: n }, (_, i) => i + 1);
};

export const fixToOneDecimalAndReplaceDotByComma = (num: number): string => {
  const roundByOne = Math.round(num * 10) / 10;
  return String(roundByOne).replace(".", ",");
};

// parameter is used for testing purpose
export const getTodaysDate = (today = new Date()): string => {
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const day = String(today.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};
