import { FunctionComponent } from "react";

import styles from "./ProgressBar.module.scss";

import { selectColor } from "@lib/helper/scoreUtils";

export interface IProgressBarProps {
  value: number;
  className?: string;
  isScore?: boolean;
}

const MIN_PROGRESS_SCORE = 5;

const ProgressBar: FunctionComponent<IProgressBarProps> = ({ value, className, isScore = false }) => {
  const roundedValue = Math.round(value > 100 ? 100 : value);
  const color = isScore ? selectColor(roundedValue) : "--default";
  const progressClassName = styles[`progress-bar__progress${color}`];
  return (
    <div className={`${styles["progress-bar"]} ${className}`}>
      <div className={styles["progress-bar__bar"]}>
        <div
          className={`${styles["progress-bar__progress"]} ${progressClassName}`}
          style={{ width: `${Math.max(MIN_PROGRESS_SCORE, roundedValue)}%` }}
        ></div>
      </div>
      {isScore && <p className={styles["progress-bar__score"]}>{roundedValue}/100</p>}
    </div>
  );
};

export default ProgressBar;
