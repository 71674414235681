import { useCallback, useEffect, useState } from "react";

import { EMPTY } from "@lib/helper/stringUtils";

// eslint-disable-next-line @typescript-eslint/ban-types
export const useInitialFetch = <T extends {}>(
  fetchFunction: () => Promise<T>
): [boolean, string, T | undefined, () => void] => {
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(EMPTY);
  const [data, setData] = useState<T | undefined>(undefined);

  const fetchData = useCallback(() => {
    setIsLoading(true);
    setErrorMessage(EMPTY);
    fetchFunction()
      .then((value) => setData(value))
      .catch((error) => setErrorMessage(error.message))
      .finally(() => setIsLoading(false));
  }, [fetchFunction]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [isLoading, errorMessage, data, fetchData];
};
