import { useRouter } from "next/router";
import Script from "next/script";
import { DefaultSeo } from "next-seo";
import { useEffect } from "react";

import Footer from "@components/footer/footer";
import Header from "@components/header/header";
import { GTM } from "@lib/gtm";
import SEO from "next-seo.config";

interface ILayoutProps {
  children: React.ReactNode;
}

export default function Layout({ children }: ILayoutProps): JSX.Element {
  const router = useRouter();

  useEffect(() => {
    router.events.on("routeChangeComplete", GTM.pageview);
    return () => {
      router.events.off("routeChangeComplete", GTM.pageview);
    };
  }, [router.events]);

  return (
    <>
      {/* Google Tag Manager - Global base code */}
      {GTM.ID && (
        <Script
          async
          id="gtag-base"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM.ID}');
          `,
          }}
        />
      )}

      {/*  Hotjar Tracking Code */}
      {process.env.NEXT_PUBLIC_HOTJAR_ID && (
        <Script
          async
          id="hotjar-base"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:${process.env.NEXT_PUBLIC_HOTJAR_ID},hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `,
          }}
        />
      )}
      <DefaultSeo {...SEO} />
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  );
}
