export const EMPTY = "";
export const FORWARD_SLASH = "/";
export const HASHTAG = "#";
export const SPACE = " ";

const SPECIALS_TO_PLAIN_CHARS: Map<string, string> = new Map([
  ["á", "a"],
  ["à", "a"],
  ["â", "a"],
  ["æ", "a"],
  ["ª", "a"],
  ["ä", "a"],
  ["ã", "a"],
  ["å", "a"],
  ["ā", "a"],
  ["ç", "c"],
  ["ć", "c"],
  ["č", "c"],
  ["é", "e"],
  ["è", "e"],
  ["ê", "e"],
  ["ë", "e"],
  ["ę", "e"],
  ["ė", "e"],
  ["ē", "e"],
  ["î", "i"],
  ["ï", "i"],
  ["ì", "i"],
  ["í", "i"],
  ["į", "i"],
  ["ī", "i"],
  ["ñ", "n"],
  ["ń", "n"],
  ["ô", "o"],
  ["œ", "o"],
  ["º", "o"],
  ["ö", "o"],
  ["ò", "o"],
  ["ó", "o"],
  ["õ", "o"],
  ["ø", "o"],
  ["û", "u"],
  ["ù", "u"],
  ["ü", "u"],
  ["ú", "u"],
  ["ū", "u"],
  ["ÿ", "y"],
]);

export function plainifyString(value: string): string {
  const result: string[] = [];
  for (let i = 0; i < value.length; i++) {
    const char = value.charAt(i);
    const plainChar = SPECIALS_TO_PLAIN_CHARS.get(char);
    result.push(plainChar ? plainChar : char);
  }
  return result.join(EMPTY);
}

export const ONLY_ALPHA_CHARS_REGEX = /^[a-z]+$/i;

export function isAlpha(char: string): boolean {
  return char.match(ONLY_ALPHA_CHARS_REGEX) !== null;
}

export function compareNatural(a: string, b: string): number {
  return plainifyString(a.toLowerCase()).localeCompare(plainifyString(b.toLowerCase()));
}

export function isEmpty(value: string): boolean {
  return value === EMPTY;
}

export function isBlank(value?: string): boolean {
  return value === undefined || isEmpty(value);
}

export function camelCaseToSnakeCase(text: string): string {
  return text
    .split(/(?=[A-Z])/)
    .join("_")
    .toLowerCase();
}
