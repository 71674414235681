import { ITheme } from "./themesModel";

import { eBrandStatus, Gender, RangePrice } from "@lib/helper/brandUtils";
import { ICriteriumNode, ISectionNode, ISubcriteriumNode } from "@lib/store/models/nodesModels";
import { Entity } from "@lib/store/normalizer";

export const BRAND_TYPE = "brand";
export const BRAND_COUNTER_TYPE = "brandCounter";
export const GENDER_TYPE = "gender";
export const PRODUCT_CATEGORY_TYPE = "productCategory";
export const THEME_NODE_TYPE = "themeNode";
export const BRAND_THEME_NODE_TYPE = "brandThemeNode";
export const BRAND_SECTION_NODE_TYPE = "brandSectionNode";
export const BRAND_SUBSECTION_NODE_TYPE = "brandSubsectionNode";
export const BRAND_CRITERIUM_NODE_TYPE = "brandCriteriumNode";
export const BRAND_SUBCRITERIUM_NODE_TYPE = "brandSubcriteriumNode";
export const SECTION_NODE_TYPE = "sectionNode";
export const CRITERIUM_NODE_TYPE = "criteriumNode";
export const SUBCRITERIUM_NODE_TYPE = "subcriteriumNode";
export const BRAND_SOURCE_TYPE = "brandSource";
export const BRAND_ORIGIN_TYPE = "brandOrigin";

export interface RankInfo {
  rank: number;
  total: number;
}

export interface IBrand extends BrandBase, Partial<BrandShowAttributes> {
  hasSheetProducts?: boolean;
}

interface BrandBase extends Entity {
  code: string;
  name: string;
  status: eBrandStatus;
}

interface BrandShowAttributes {
  rangePrice: RangePrice;
  rankInfo: RankInfo;
  reviewsCount: number;
  score: number;
  updatedAt: string;
  cessationOfActivity: boolean;
  hasFashionScore: boolean;

  bannerUrl?: string;
  hasActualities: boolean;
  logoUrl?: string;
  qualityScore?: number;
  websiteUrl?: string;
}

export interface IGender extends Entity {
  code: Gender;
  order: number;
}

export interface IProductCategory extends Entity {
  code: string;
  name: string;
  order: number;
  pictureCode: string;
}

export interface BrandShow extends BrandBase, BrandShowAttributes {}

export interface IBrandSectionNode extends ISectionNode {
  brandThemeNodeId: string;
  themeCode: string;
}

export interface IBrandCriteriumNode extends ICriteriumNode {
  brandSectionNodeId: string;
}

export interface IBrandSubcriteriumNode extends ISubcriteriumNode {
  brandCriteriumNodeId: string;
}

export enum SourceReliability {
  reliable = "reliable",
  accepted = "accepted",
  warning = "warning",
  waiting = "waiting",
}

export interface IBrandSource extends Entity {
  name: string;
  link: string;
  reliability: SourceReliability;
  date: string;
  brandCriteriumNodeId: string;
}

export interface IBrandOrigin extends Entity {
  productionStepCode: string;
  productionStepTitle: string;
  percentage: number;
  unknownPercentage: boolean;
  country: string;
}

export interface IBrandData {
  brand: IBrand;
  themes: ITheme[];
  sectionsByThemeId: { [id: string]: IBrandSectionNode[] };
  criteriumsBySectionId: { [id: string]: IBrandCriteriumNode[] };
  subcriteriumsByCriteriumId: { [id: string]: IBrandSubcriteriumNode[] };
  brandSourcesByCriteriumId: { [id: string]: IBrandSource[] };
  genders: IGender[];
  productCategories: IProductCategory[];
  origins: IBrandOrigin[];
}

export interface IAllBrandsInfo {
  brands: IBrand[];
  indexes: string[];
}

export interface IBrandCounter extends Entity {
  name: string;
  score: number;
}
