import Link from "next/link";
import { CSSProperties, FunctionComponent } from "react";

import styles from "./SearchBarResults.module.scss";

import { IData } from "@components/generic/search/searchBar";
import { compareNatural } from "@lib/helper/stringUtils";

interface ISearchBarResultsProps {
  userEntry: string;
  hits: IData[];
  noResultPlaceholder: string;
  renderInfo(isThereHits: boolean, userEntry: string, displayEvaluationLink: boolean): JSX.Element;
  onReset(): void;
  style?: CSSProperties;
  className?: string;
}

const _displayEvaluationLink = (userEntry: string, hits: IData[]): boolean => {
  if (hits.length !== 1) return true;
  return compareNatural(userEntry, hits[0].name) !== 0;
};

const SearchBarResults: FunctionComponent<ISearchBarResultsProps> = ({
  userEntry,
  hits,
  noResultPlaceholder,
  renderInfo,
  onReset,
  style,
  className,
}) => {
  const _renderHits = (hits: IData[]): JSX.Element => {
    const isThereHits = hits.length !== 0;
    return (
      <div className={styles["search-bar-results__hits"]}>
        {isThereHits ? (
          <>{hits.map((hit) => _renderHit(hit))}</>
        ) : (
          <div className={styles["search-bar-results__no-result"]}>
            <strong>{noResultPlaceholder}</strong>
          </div>
        )}
        <div className={styles["search-bar-results__info"]}>
          {renderInfo(isThereHits, userEntry, _displayEvaluationLink(userEntry, hits))}
        </div>
      </div>
    );
  };

  const _renderHit = ({ name, href }: IData): JSX.Element => {
    return (
      <Link key={name} href={href} id={"cta-brand-result-search"}>
        <button className={styles["search-bar-results__hit"]} onClick={onReset}>
          <strong>{name}</strong>
        </button>
      </Link>
    );
  };

  return (
    <div className={`${styles["search-bar-results"]} ${className}`} style={style}>
      {_renderHits(hits)}
    </div>
  );
};

export default SearchBarResults;
