import Image from "next/image";
import { FunctionComponent } from "react";

import styles from "./SearchLoader.module.scss";

import Loader from "@components/generic/loader";
import { createSizesMediaQueries } from "@lib/helper/imageUtils";
import { searchbarImages } from "@lib/images";

interface ISearchLoaderProps {
  className?: string;
}

const SearchLoader: FunctionComponent<ISearchLoaderProps> = ({ className }) => {
  return (
    <Loader className={`${styles["search-loader"]} ${className}`}>
      <div className={styles["search-loader__image"]}>
        <Image
          src={searchbarImages.loader}
          alt={"Loading..."}
          width={125}
          height={125}
          priority={true}
          sizes={createSizesMediaQueries({ smSize: 35, mdSize: 25, xlSize: 15 })}
          style={{
            width: "100%",
            height: "auto",
          }}
        />
      </div>
    </Loader>
  );
};

export default SearchLoader;
