import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { Fragment } from "react";

import styles from "./Footer.module.scss";

import { createSizesMediaQueries } from "@lib/helper/imageUtils";
import { FORWARD_SLASH } from "@lib/helper/stringUtils";
import { badgeImages } from "@lib/images";

interface ISection {
  title: string;
  footerLinks: IFooterLink[];
  key: string;
  locale: (string | undefined)[];
}

interface IFooterLink {
  key: string;
  href: string;
  text: string;
  image?: string;
}

function Footer() {
  const router = useRouter();
  const { t } = useTranslation(["footer", "common"]);

  const SECTIONS: ISection[] = [
    {
      key: "about",
      title: t("sections.about"),
      locale: ["fr", "en"],
      footerLinks: [
        { href: t("links.manifesto.href"), text: t("links.manifesto.text"), key: "manifesto" },
        { href: t("links.legal.href"), text: t("links.legal.text"), key: "legal" },
        { href: t("links.cgu.href"), text: t("links.cgu.text"), key: "privacy_policy" },
      ],
    },
    {
      key: "methodology",
      title: t("sections.methodology"),
      locale: ["en"],
      footerLinks: [
        {
          href: t("links.evaluation_methodology.href"),
          text: t("links.evaluation_methodology.text"),
          key: "evaluation_methodology",
        },
        { href: t("links.independence.href"), text: t("links.independence.text"), key: "independence" },
        { href: t("links.faq.href"), text: t("links.faq.text"), key: "faq" },
      ],
    },
    {
      key: "solutions",
      title: t("sections.solutions"),
      locale: ["fr", "en"],
      footerLinks: [
        { href: t("links.blog.href"), text: t("links.blog.text"), key: "blog" },
        { href: t("links.mobile_app.href"), text: t("links.mobile_app.text"), key: "mobile_app" },
        { href: t("links.brand_space.href"), text: t("links.brand_space.text"), key: "brand_space" },
      ],
    },
    {
      key: "app_download",
      title: t("sections.app_download"),
      locale: ["fr"],
      footerLinks: [
        {
          href: t("stores.app_store.href", { ns: "common" }),
          text: t("stores.app_store.text", { ns: "common" }),
          key: "app_store",
          image: badgeImages.fr.appStore,
        },
        {
          href: t("stores.google_play.href", { ns: "common" }),
          text: t("stores.google_play.text", { ns: "common" }),
          key: "google_play",
          image: badgeImages.fr.googlePlay,
        },
      ],
    },
  ];

  const _renderTextLink = (href: string, text: string, className?: string): JSX.Element => {
    const linkTargetOption = href.charAt(0) === FORWARD_SLASH ? undefined : "popup";
    return (
      <a target={linkTargetOption} className={className}>
        {text}
      </a>
    );
  };

  const _renderFooterLink = ({ href, text, image, key }: IFooterLink): JSX.Element => {
    if (!href) return <Fragment key={key}></Fragment>;

    return (
      <li key={key} className={styles.footer__link}>
        <Link href={href} legacyBehavior>
          {image ? (
            <a target="popup" className={styles["footer__badge-container"]}>
              <Image
                src={image}
                alt={text}
                width={119}
                height={35}
                sizes={createSizesMediaQueries({ smSize: 40, mdSize: 15, xlSize: 10 })}
                style={{
                  width: "100%",
                  height: "auto",
                }}
              />
            </a>
          ) : (
            _renderTextLink(href, text, styles.footer__link__text)
          )}
        </Link>
      </li>
    );
  };

  const _renderSection = ({ title, footerLinks, key, locale }: ISection): JSX.Element => {
    if (!locale.includes(router.locale)) return <Fragment key={key}></Fragment>;

    return (
      <div className={styles.footer__section} key={key}>
        <strong className={styles.footer__section__title}>{title}</strong>
        <ul className={styles.footer__section__footer_links}>{footerLinks && footerLinks.map(_renderFooterLink)}</ul>
      </div>
    );
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.footer__section_container}>{SECTIONS.map(_renderSection)}</div>
    </footer>
  );
}

export default Footer;
