import Image from "next/image";
import { useTranslation } from "next-i18next";
import { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import { useKeyPressEvent } from "react-use";

import styles from "./SearchBar.module.scss";

import APICallManager from "@components/generic/apiCallManager";
import ErrorMessage from "@components/generic/ErrorMessage";
import { isModalOpened } from "@components/generic/modal/modal";
import SearchBarResults from "@components/generic/search/searchBarResults";
import SearchLoader from "@components/generic/search/searchLoader";
import Header from "@components/header/header";
import filterEntities from "@lib/helper/searchUtils";
import { EMPTY, isEmpty } from "@lib/helper/stringUtils";
import { MOBILE_THRESHOLD } from "@lib/helper/utils";
import { commonImages, searchbarImages } from "@lib/images";

interface ISearchBarProps {
  fetchAllData(): Promise<IData[]>;
  noResultPlaceholder: string;
  renderInfo(isThereHits: boolean, userEntry: string, displayEvaluationLink: boolean): JSX.Element;
  placeholder?: string;
  className?: string;
  declaredInHeader?: boolean;
}

export interface IData {
  name: string;
  href: string;
}

const SearchBar: FunctionComponent<ISearchBarProps> = ({
  fetchAllData,
  noResultPlaceholder,
  renderInfo,
  placeholder,
  className,
  declaredInHeader = false,
}) => {
  const { t } = useTranslation("error");

  const [userEntry, setUserEntry] = useState(EMPTY);
  const [displayResults, setDisplayResults] = useState(false);
  const [doFetch, setDoFetch] = useState(false);
  const resultRef = useRef<null | HTMLDivElement>(null);

  // Filtering
  const _onSearchValueChanged = (value: string) => {
    setUserEntry(value);
  };

  // Refresh method
  useEffect(() => {
    setDisplayResults(userEntry !== EMPTY);
  }, [userEntry]);

  // scroll on top for safari
  useEffect(() => {
    if (displayResults && window.innerWidth < MOBILE_THRESHOLD) {
      window.scrollTo(0, 0);
    }
  }, [displayResults]);

  // Resetting
  const _reset = useCallback(() => {
    if (displayResults || !isEmpty(userEntry)) {
      setDisplayResults(false);
      setUserEntry(EMPTY);
    }
  }, [displayResults, userEntry]);

  const _handleCloseClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      _reset();
    },
    [_reset],
  );

  const _backDropHandler = useCallback(
    (e: MouseEvent) => {
      if (!resultRef?.current?.contains(e.target as Node) && !isModalOpened()) {
        _reset();
      }
    },
    [_reset],
  );

  useEffect(() => {
    window.addEventListener("click", _backDropHandler, true);
    return () => window.removeEventListener("click", _backDropHandler);
  }, [_backDropHandler]);

  // Key handling

  useKeyPressEvent("Escape", _reset);

  const expandedClassName = displayResults ? styles["search-bar--expanded"] : EMPTY;

  // Results

  const _renderLoader = (): JSX.Element => {
    return <SearchLoader className={styles["evaluation-link__loading"]} />;
  };

  const _renderError = (message: string, onRetry?: () => void): JSX.Element => {
    // /!\ this message is a translation key and not a real message!
    return <ErrorMessage message={t(message)} onRetry={onRetry} />;
  };

  const _renderSuccess = (data: IData[]): JSX.Element => {
    const filteredData = filterEntities(userEntry, data).slice(0, 5);
    return (
      <SearchBarResults
        userEntry={userEntry}
        hits={filteredData}
        noResultPlaceholder={noResultPlaceholder}
        renderInfo={renderInfo}
        onReset={_reset}
      />
    );
  };

  return (
    <div
      className={`${styles["search-bar"]} ${className} ${expandedClassName}`}
      onClick={() => setDoFetch(true)}
      ref={resultRef}
    >
      {!declaredInHeader && <Header className={styles["search-bar__header"]} forceLightMode />}
      <input
        id="search-bar"
        placeholder={placeholder}
        className={styles["search-bar__input"]}
        onChange={(event) => _onSearchValueChanged(event.currentTarget.value)}
        title="Search bar"
        value={userEntry}
      />
      <div className={styles["search-bar__icon"]}>
        <Image
          src={searchbarImages.search}
          alt={"Search"}
          width={24}
          height={24}
          priority={true}
          style={{
            maxWidth: "100%",
            height: "auto",
          }}
        />
      </div>
      {displayResults && (
        <div className={styles["search-bar__close"]} onClick={_handleCloseClick}>
          <Image
            src={commonImages.close}
            alt={"Close button"}
            width={24}
            height={24}
            style={{
              maxWidth: "100%",
              height: "auto",
            }}
          />
        </div>
      )}
      <div className={styles["search-bar__results"]} style={{ display: displayResults ? "block" : "none" }}>
        {doFetch && (
          <APICallManager callAPI={fetchAllData} renderLoader={_renderLoader} renderError={_renderError}>
            {_renderSuccess}
          </APICallManager>
        )}
      </div>
    </div>
  );
};

export default SearchBar;
