import { LANG_OPTIONS } from "@components/header/langSelector";

type pageCategory = "home" | "brands" | "brand_index" | "legal" | "error";
type eventName =
  | "page_view"
  | "click_evalBrand"
  | "click_theme_brand"
  | "click_section_brand"
  | "click_criterium_brand"
  | "click_productionSites"
  | "click_productionStep";

export interface DataLayer extends Partial<DataLayerAttributes> {
  event: eventName;
}
interface DataLayerAttributes {
  page: string;
  pageCategory: pageCategory;
  brandName: string;
  storeName: string;
  themeName: string;
  sectionName: string;
  criteriumName: string;
  stepName: string;
  userEntry: string;
}

declare global {
  interface Window {
    dataLayer: DataLayer[];
  }
}

// exported only for testing purposes
export const setPageCategoryFromUrl = (url: string): pageCategory => {
  const parsedUrl = new URL(url);
  const pathnameParts = parsedUrl.pathname.split("/").filter((e) => e);
  let firstElement = pathnameParts.shift();
  if (firstElement === undefined) return "home";

  if (LANG_OPTIONS.map((lang) => lang.value).includes(firstElement)) {
    firstElement = pathnameParts.shift();
    if (firstElement === undefined) return "home";
  }

  switch (firstElement) {
    case "brands":
      return "brands";
    case "legal-notices":
      return "legal";
    case "terms-of-use":
      return "legal";
    case "all-brands":
      return "brand_index";
    default:
      return "error";
  }
};

// exported only for testing purposes
export const setBrandNameFromUrl = (url: string): string | undefined => {
  const parsedUrl = new URL(url);
  const pathnameParts = parsedUrl.pathname.split("/").filter((e) => e);
  let firstElement = pathnameParts.shift();
  if (firstElement === undefined) return;

  if (LANG_OPTIONS.map((lang) => lang.value).includes(firstElement)) {
    firstElement = pathnameParts.shift();
    if (firstElement === undefined) return;
  }

  if (firstElement !== "brands") return;

  return pathnameParts.shift();
};

export class GTM {
  static ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;

  static trackEvent = (event?: DataLayer) => {
    if (event === undefined) return;
    if (!this.ID) return;

    window.dataLayer.push(event);
  };

  static pageview = (url: string) => {
    const event: DataLayer = {
      event: "page_view",
      page: url,
    };
    this.trackEvent(event);
  };
}
