import { AxiosError } from "axios";

export const APIErrorCodes = {
  GENERAL: "api-error",
  ENTITY_NOT_FOUND: "entity-not-found",
  FORBIDDEN: "forbidden",
  INTERNAL_SERVER: "internal-server-error",
  INVALID_TOKEN: "invalid-token",
  NO_CONTENT: "no-content",
  NO_BRAND_ACCESS: "no-brand-access",
  BAD_GATEWAY: "bad-gateway",
};

const errorCodes = [
  APIErrorCodes.GENERAL,
  APIErrorCodes.ENTITY_NOT_FOUND,
  APIErrorCodes.FORBIDDEN,
  APIErrorCodes.INTERNAL_SERVER,
  APIErrorCodes.INVALID_TOKEN,
  APIErrorCodes.NO_BRAND_ACCESS,
  APIErrorCodes.NO_CONTENT,
  APIErrorCodes.BAD_GATEWAY,
] as const;
export type ClearFashionErrorCodes = (typeof errorCodes)[number];

export const LOCALIZED_ERRORS = [APIErrorCodes.INTERNAL_SERVER, APIErrorCodes.GENERAL];

export class APIError extends AxiosError {
  public withCustomMessage: boolean;
  constructor(message?: string) {
    super();
    this.message = message ?? "API Error";
    this.code = APIErrorCodes.GENERAL;
    this.withCustomMessage = message !== undefined;
  }
}

const isClearFashionErrorCode = (code: unknown): code is ClearFashionErrorCodes => {
  return typeof code === "string" && errorCodes.includes(code as ClearFashionErrorCodes);
};

export const isClearFashionError = (error: Error | APIError): error is APIError => {
  if ((error as APIError).code === undefined) return false;

  return isClearFashionErrorCode((error as APIError).code);
};

export class EntityNotFoundError extends APIError {
  constructor(message?: string) {
    super();
    this.message = message ?? "Entity Not Found";
    this.code = APIErrorCodes.ENTITY_NOT_FOUND;
    this.withCustomMessage = message !== undefined;
  }
}

export class ForbiddenError extends APIError {
  constructor(message?: string) {
    super();
    this.message = message ?? "Forbidden Error";
    this.code = APIErrorCodes.FORBIDDEN;
    this.withCustomMessage = message !== undefined;
  }
}

export class InternalServerError extends APIError {
  constructor(message?: string) {
    super();
    this.message = message ?? "Internal Server Error";
    this.code = APIErrorCodes.INTERNAL_SERVER;
    this.withCustomMessage = message !== undefined;
  }
}

export class InvalidTokenError extends APIError {
  constructor(message?: string) {
    super();
    this.message = message ?? "Invalid Token";
    this.code = APIErrorCodes.INVALID_TOKEN;
    this.withCustomMessage = message !== undefined;
  }
}

export class NoContentError extends APIError {
  constructor(message?: string) {
    super();
    this.message = message ?? "No content";
    this.code = APIErrorCodes.NO_CONTENT;
    this.withCustomMessage = message !== undefined;
  }
}

export class NoBrandAccessError extends APIError {
  constructor(message?: string) {
    super();
    this.message = message ?? "No Brand Access";
    this.code = APIErrorCodes.NO_BRAND_ACCESS;
    this.withCustomMessage = message !== undefined;
  }
}

export class BadGatewayError extends APIError {
  constructor(message?: string) {
    super();
    this.message = message ?? "Bad gateway";
    this.code = APIErrorCodes.NO_BRAND_ACCESS;
    this.withCustomMessage = message !== undefined;
  }
}

const extractMessageFromError = (error: AxiosError) => {
  return (error.response?.data as { message?: string } | undefined)?.message;
};

export function extractError(error: AxiosError, withLog = true): APIError {
  withLog ?? console.log("error: ", error);
  if (isClearFashionError(error) && isClearFashionErrorCode(error.code)) {
    return error;
  }

  const apiMessage = extractMessageFromError(error);

  switch (error.response?.status) {
    case 401:
      return new InvalidTokenError(apiMessage);
    case 403:
      return new ForbiddenError(apiMessage);
    case 404:
      return new EntityNotFoundError(apiMessage);
    case 500:
      return new InternalServerError(apiMessage);
    case 204:
      return new NoContentError(apiMessage);
    case 502:
      return new BadGatewayError(apiMessage);
    default:
      return new APIError(apiMessage);
  }
}
