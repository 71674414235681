import { TFunction } from "i18next";

import { starImages } from "@lib/images";

export const scoreRGB = {
  lowest: "--lowest",
  low: "--low",
  medium: "--medium",
  high: "--high",
  highest: "--highest",
};

export function selectColor(score: number): string {
  switch (true) {
    case score >= 80:
      return scoreRGB.highest;
    case score >= 60:
      return scoreRGB.high;
    case score >= 40:
      return scoreRGB.medium;
    case score >= 20:
      return scoreRGB.low;
    case score < 20:
      return scoreRGB.lowest;
    default:
      return scoreRGB.medium;
  }
}

const computeExcellentLimitScore = (maximalScore: number): number => {
  return maximalScore - maximalScore / 5;
};

const computeVeryGoodLimitScore = (maximalScore: number): number => {
  return maximalScore - (maximalScore / 5) * 2;
};

const computeGoodLimitScore = (maximalScore: number): number => {
  return maximalScore - (maximalScore / 5) * 3;
};

const computeUnsatisfactoryLimitScore = (maximalScore: number): number => {
  return maximalScore - (maximalScore / 5) * 4;
};

export const getScoreAsText = (score: number, t: TFunction, maximalScore = 100) => {
  switch (true) {
    case score >= computeExcellentLimitScore(maximalScore):
      return t("score.excellent");
    case score >= computeVeryGoodLimitScore(maximalScore):
      return t("score.veryGood");
    case score >= computeGoodLimitScore(maximalScore):
      return t("score.good");
    case score >= computeUnsatisfactoryLimitScore(maximalScore):
      return t("score.unsatisfactory");
    case score < computeUnsatisfactoryLimitScore(maximalScore):
      return t("score.veryUnsatisfactory");
    default:
      return t("score.good");
  }
};

export function getStarImage(index: number, score?: number) {
  if (!score) return starImages.greyStar;

  const firstHalfBoundary = index - 0.75;
  const secondHalfBoundary = index - 0.25;

  if (score < firstHalfBoundary) return starImages.emptyStar;
  if (score < secondHalfBoundary) return starImages.halfFilledStar;
  return starImages.filledStar;
}
