import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { FunctionComponent } from "react";

import BrandSearchInfo from "@components/brand/brandSearchInfo";
import SearchBar, { IData } from "@components/generic/search/searchBar";
import { getBrandPath } from "@lib/helper/pathUtils";
import { getAllBrands } from "@lib/store/brands";

export interface IBrandSearchBarProps {
  className?: string;
  declaredInHeader?: boolean;
}

const BrandSearchBar: FunctionComponent<IBrandSearchBarProps> = ({ className, declaredInHeader = false }) => {
  const { t } = useTranslation("common");
  const { locale } = useRouter();

  const _fetchAllBrand = (): Promise<IData[]> => {
    return getAllBrands({ locale, lightVersion: true }).then((brands) => {
      return brands.map(({ name, code }) => {
        return { name, href: getBrandPath(code) };
      });
    });
  };

  const _renderInfo = (isThereHits: boolean, userEntry: string, displayEvaluationLink: boolean) => {
    return (
      <BrandSearchInfo isThereHits={isThereHits} userEntry={userEntry} displayEvaluationLink={displayEvaluationLink} />
    );
  };

  return (
    <SearchBar
      fetchAllData={_fetchAllBrand}
      noResultPlaceholder={t("search.noEvaluation")}
      placeholder={t("search.placeholder")}
      className={className}
      renderInfo={_renderInfo}
      declaredInHeader={declaredInHeader}
    />
  );
};

export default BrandSearchBar;
