import Image from "next/image";
import Link from "next/link";
import { FunctionComponent, ReactNode } from "react";

import styles from "./CTAButton.module.scss";

import { EMPTY } from "@lib/helper/stringUtils";

export type ButtonType = "primary" | "secondary" | "tertiary";
export type ImagePosition = "left" | "right";

export interface ICTAButtonProps {
  children: ReactNode;
  href?: string;
  onClick?(): void;
  className?: string;
  type?: ButtonType;
  image?: string;
  imagePosition?: ImagePosition;
  imageSizeInEm?: number;
  shouldPopup?: boolean;
  id?: string;
}

const CTAButton: FunctionComponent<ICTAButtonProps> = ({
  children,
  href,
  onClick,
  className,
  image,
  imageSizeInEm,
  id,
  type = "primary",
  imagePosition = "left",
  shouldPopup = false,
}) => {
  const _renderImage = (): JSX.Element => {
    if (!image) return <></>;
    const style = imageSizeInEm ? { minWidth: `${imageSizeInEm}em`, minHeight: `${imageSizeInEm}em` } : {};
    const color = styles[`cta-button__image--${type}`];

    return (
      <div className={`${styles["cta-button__image"]} ${styles[imagePosition]} ${color}`} style={style}>
        <Image
          src={image}
          alt="icon"
          priority={true}
          fill
          sizes="100vw"
          style={{
            objectFit: "contain",
          }}
        />
      </div>
    );
  };

  const _renderContent = (): JSX.Element => (
    <>
      {imagePosition == "left" && _renderImage()}
      {children}
      {imagePosition == "right" && _renderImage()}
    </>
  );

  // Using the `<a>` tag here for internal and external navigation for SEO optimisation purposes.
  const _renderLink = (href: string): JSX.Element => (
    <Link
      href={href}
      id={id}
      className={`${styles["cta-button"]} ${styles[type]} ${className}`}
      target={shouldPopup ? "popup" : EMPTY}
    >
      {_renderContent()}
    </Link>
  );

  const _renderButton = (): JSX.Element => (
    <button id={id} className={`${styles["cta-button"]} ${styles[type]} ${className}`} onClick={onClick}>
      {_renderContent()}
    </button>
  );

  if (href) {
    return _renderLink(href);
  }

  return _renderButton();
};

export default CTAButton;
