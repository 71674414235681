import { useTranslation } from "next-i18next";
import { FunctionComponent } from "react";

import styles from "./BrandSearchInfo.module.scss";

import EvaluationLink from "@components/brand/evaluationLink";
import ArrowLink from "@components/generic/arrowLink";
import CTAButton from "@components/generic/ctaButton";
import { getAllBrandPath } from "@lib/helper/pathUtils";

interface IBrandSearchInfoProps {
  isThereHits: boolean;
  userEntry: string;
  displayEvaluationLink: boolean;
  className?: string;
}

const BrandSearchInfo: FunctionComponent<IBrandSearchInfoProps> = ({
  isThereHits,
  userEntry,
  displayEvaluationLink,
  className,
}) => {
  const { t } = useTranslation("common");

  return (
    <div className={`${styles["brand-search-info"]} ${className}`}>
      {isThereHits ? (
        <>
          <CTAButton
            href={getAllBrandPath()}
            className={styles["brand-search-info__cta"]}
            id={"cta-all-brands-search-succeeded"}
          >
            {t("search.seeAllBrand")}
          </CTAButton>
          {displayEvaluationLink && <EvaluationLink userEntry={userEntry} />}
        </>
      ) : (
        <>
          <EvaluationLink userEntry={userEntry} className={styles["brand-search-info__cta"]} type="cta" />
          <ArrowLink href={getAllBrandPath()} id={"cta-all-brands-search-failed"}>
            {t("search.seeAllBrand")}
          </ArrowLink>
        </>
      )}
    </div>
  );
};

export default BrandSearchInfo;
