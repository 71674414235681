import Image from "next/image";
import { FunctionComponent } from "react";

import styles from "./Dropdown.module.scss";

import { commonImages } from "@lib/images";

export interface IOption {
  value: string;
  label?: string;
  shortLabel?: string;
  default?: boolean;
}

interface IDropdownProps {
  title: string;
  options: IOption[];
  selectedItemCallback: (value: string) => void;
  className?: string;
  isDarkMode?: boolean;
}

const Dropdown: FunctionComponent<IDropdownProps> = ({
  title,
  options,
  selectedItemCallback,
  className,
  isDarkMode,
}) => {
  const titleClassName = `${styles["dropdown__title"]} ${isDarkMode ? styles["dropdown__title--white"] : ""}`;
  const iconClassName = `${styles["dropdown__icon"]} ${isDarkMode ? styles["dropdown__icon--white"] : ""}`;

  const _renderOption = ({ value, shortLabel, label }: IOption): JSX.Element => {
    return (
      <button key={value} className={styles["dropdown__option"]} onClick={() => selectedItemCallback(value)}>
        {!!shortLabel && (
          <>
            <strong className={styles["dropdown__short-label"]}>{shortLabel}</strong>&nbsp;
          </>
        )}
        <div>{label}</div>
      </button>
    );
  };

  return (
    <div className={`${styles["dropdown"]} ${className}`}>
      <div className={titleClassName}>
        <strong>{title}</strong>
        <div className={iconClassName}>
          <Image
            src={commonImages.dropdown}
            alt="dropdown"
            height={24}
            width={24}
            priority={true}
            sizes="3vw"
            style={{
              width: "100%",
              height: "auto",
            }}
          />
        </div>
      </div>
      <div className={styles["dropdown__content"]}>{options.map((option) => _renderOption(option))}</div>
    </div>
  );
};

export default Dropdown;
