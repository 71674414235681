import Image from "next/image";
import { useTranslation } from "next-i18next";
import { FunctionComponent } from "react";

import styles from "./AskEvaluationModal.module.scss";

import CTAButton from "@components/generic/ctaButton";
import ProgressBar from "@components/generic/progressBar";
import { createSizesMediaQueries } from "@lib/helper/imageUtils";
import { onboardingImages } from "@lib/images";

export interface IAskEvaluationModalProps {
  name: string;
  score: number;
  onClose(): void;
  className?: string;
}

const OBJECTIVE_GOAL = 100;

const AskEvaluationModal: FunctionComponent<IAskEvaluationModalProps> = ({ name, score, onClose, className }) => {
  const { t } = useTranslation(["brand", "common"]);

  return (
    <div className={`${styles["ask-evaluation-modal"]} ${className}`}>
      <div className={styles["ask-evaluation-modal__content"]}>
        <h2>{t("evaluation.title")}</h2>
        <h4>{t("evaluation.brandRequest", { name })}</h4>
        <ProgressBar value={score} className={styles["ask-evaluation-modal__progress"]} />
        <div className={styles["ask-evaluation-modal__count"]}>
          <strong>{score}</strong>
          &nbsp;
          {t("evaluation.requestCount", { count: score })}
          &nbsp;
          {t("evaluation.requestObjective", { objective: OBJECTIVE_GOAL })}
        </div>
        <div className={styles["ask-evaluation-modal__process"]}>
          <p>
            <strong>{t("evaluation.process")}</strong>
          </p>
        </div>
        <div className={styles["ask-evaluation-modal__info"]}>
          <p>{t("evaluation.cheering")}</p>
        </div>
        <div className={styles["ask-evaluation-modal__image"]}>
          <Image
            src={onboardingImages.stepSuccess}
            alt="Success"
            width={168}
            height={168}
            sizes={createSizesMediaQueries({ smSize: 70, mdSize: 20 })}
            style={{
              width: "100%",
              height: "auto",
            }}
          />
        </div>
      </div>
      <CTAButton onClick={onClose} className={styles["ask-evaluation-modal__close-cta"]}>
        {t("action.close", { ns: "common" })}
      </CTAButton>
    </div>
  );
};

export default AskEvaluationModal;
