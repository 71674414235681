import { FunctionComponent, ReactNode } from "react";

import styles from "./ArrowLink.module.scss";

import CTAButton, { ButtonType } from "@components/generic/ctaButton";
import { commonImages } from "@lib/images";

export interface IArrowLinkProps {
  children: ReactNode;
  href?: string;
  onClick?(): void;
  className?: string;
  type?: ButtonType;
  shouldPopup?: boolean;
  id?: string;
}

const ArrowLink: FunctionComponent<IArrowLinkProps> = ({
  children,
  href,
  onClick,
  type = "tertiary",
  className,
  shouldPopup,
  id,
}) => {
  return (
    <CTAButton
      href={href}
      onClick={onClick}
      className={`${styles["arrow-link"]} ${className}`}
      type={type}
      image={commonImages.arrow}
      imageSizeInEm={1}
      shouldPopup={shouldPopup}
      id={id}
    >
      <div className={styles["arrow-link__content"]}>{children}</div>
    </CTAButton>
  );
};

export default ArrowLink;
