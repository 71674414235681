import { AxiosResponse } from "axios";

import { ITheme } from "./models/themesModel";

import { EntityNotFoundError } from "@lib/api/apiErrors";
import { APIResponse, APIResponseEntity } from "@lib/api/clear_fashion/types";
import { EMPTY } from "@lib/helper/stringUtils";
import {
  IBrand,
  IBrandCounter,
  IBrandCriteriumNode,
  IBrandOrigin,
  IBrandSectionNode,
  IBrandSource,
  IBrandSubcriteriumNode,
  IGender,
  IProductCategory,
} from "@lib/store/models/brandsModels";
import { ICriteriumNode, ISectionNode, ISubcriteriumNode, IThemeNode } from "@lib/store/models/nodesModels";
import { IProduct } from "@lib/store/models/productsModel";
import { IProductionStep } from "@lib/store/ProductionStep";

const ENTITIES_TYPES = [
  "brand",
  "product",
  "themeNode",
  "brandThemeNode",
  "sectionNode",
  "brandSectionNode",
  "brandCriteriumNode",
  "brandSubcriteriumNode",
  "criteriumNode",
  "subcriteriumNode",
  "brandSource",
  "brandSubsectionNode",
  "productCategory",
  "gender",
  "brandCounter",
  "brandOrigin",
  "productionStep",
  "theme",
  "productSection",
  "productCriterium",
  "productSubcriterium",
] as const;

export type EntityTypes = (typeof ENTITIES_TYPES)[number];
export type EntitiesAttributes =
  | IBrand
  | IProduct
  | IThemeNode
  | ITheme
  | ISectionNode
  | IBrandSectionNode
  | IBrandCriteriumNode
  | IBrandSubcriteriumNode
  | ICriteriumNode
  | ISubcriteriumNode
  | IBrandSource
  | IProductCategory
  | IGender
  | IBrandCounter
  | IBrandOrigin
  | IProductionStep;

export interface Entity {
  id: string;
  relationships?: EntityRelationships;
}

type EntityRelationships = {
  [key in EntityTypes]?: string | string[];
};

interface Results {
  [type: string]: string[];
}

export type Entities<T> = {
  [key in EntityTypes]?: {
    [id: string]: Extract<EntitiesAttributes, T>;
  };
};

export interface NormalizedAPIResponse<T> {
  entities: Entities<T>;
  results: Results;
}

function isEntityType(entityType: string): entityType is EntityTypes {
  return ENTITIES_TYPES.includes(entityType as EntityTypes);
}

const normalizeItem = <T>(
  entities: Entities<T>,
  entity: APIResponseEntity,
  relationships?: EntityRelationships,
): Entities<T> => {
  return {
    ...entities,
    [entity.type]: {
      ...entities[entity.type],
      [entity.id]: {
        ...entity.attributes,
        id: entity.id,
        relationships: relationships,
      },
    },
  };
};

const normalizeRelationships = (apiEntity: APIResponseEntity): EntityRelationships => {
  if (!apiEntity.relationships) return {};
  let relationships: EntityRelationships = {};

  for (const [key, value] of Object.entries(apiEntity.relationships)) {
    if (!isEntityType(key)) continue;
    if (Array.isArray(value.data)) {
      relationships[key] = value.data.map((rel) => rel.id);
    } else if (value.data) {
      relationships[key] = value.data.id;
    }
  }
  relationships = Object.fromEntries(Object.entries(relationships).filter(([, value]) => value != null));

  return relationships;
};

export function normalize<T>(response: APIResponse) {
  const apiResults = !Array.isArray(response.data) ? [response.data] : response.data;
  const includedApiResults = response.included;

  let entities: Entities<T> = {};
  const results: Results = {};

  if (!response.data) {
    return {
      entities,
      results,
    };
  }

  if (includedApiResults) {
    includedApiResults.forEach((entity) => {
      const relationships: EntityRelationships = normalizeRelationships(entity);
      entities = normalizeItem(entities, entity, relationships);
    });
  }

  apiResults.forEach((res) => {
    if (res) {
      const relationships: EntityRelationships = normalizeRelationships(res);
      entities = normalizeItem(entities, res, relationships);
      if (!results[res.type]) {
        results[res.type] = [];
      }
      results[res.type].push(res.id);
    }
  });

  if (!Array.isArray(response.data)) {
    results[response.data.type] = [response.data.id];
  }
  return {
    entities,
    results,
  };
}

export function getNormalizedData<T>(response: AxiosResponse<APIResponse>): NormalizedAPIResponse<T> {
  if (!response) {
    const errorMsg = "Expected response is empty";
    throw Error(errorMsg);
  }
  const normalizedData = normalize<T>(response.data);

  return normalizedData;
}

export function getRelationshipId(entity: Entity | null, relationshipType: EntityTypes): string {
  if (!entity || entity.relationships == null) return EMPTY;
  if (!(relationshipType in entity.relationships)) return EMPTY;

  const entityId = entity.relationships[relationshipType];
  if (!entityId) return EMPTY;
  if (Array.isArray(entityId)) return EMPTY;
  return entityId;
}

export function getRelationshipIds(entity: Entity | null, relationshipType: EntityTypes): string[] {
  if (!entity || entity.relationships == null) return [];
  if (!(relationshipType in entity.relationships)) return [];

  const entityIds = entity.relationships[relationshipType];
  if (!entityIds) return [];
  if (!Array.isArray(entityIds)) return [entityIds];
  return entityIds;
}

export function mergeEntities<T extends Entity>(
  brandEntities: T[],
  entities: Entities<EntitiesAttributes>,
  entityType: EntityTypes,
): T[] {
  return brandEntities.map((brandEntity) => mergeEntity(brandEntity, entities, entityType));
}

export function mergeEntity<T extends Entity>(
  brandEntity: T,
  entities: Entities<EntitiesAttributes>,
  entityType: EntityTypes,
): T {
  const entityId = getRelationshipId(brandEntity, entityType);
  const genericEntities = entities[entityType];
  if (!genericEntities) return brandEntity;

  const genericEntity = genericEntities[entityId];
  return {
    ...genericEntity,
    ...brandEntity, // brandEntity needs to be second to inherit relationships
  };
}

export interface IExtractSingleOptions<T> {
  errorMessage?: string;
  filter?: (data: T) => boolean;
}

export const extractSingle = <T>(
  type: EntityTypes,
  { entities, results }: NormalizedAPIResponse<EntitiesAttributes>,
  options?: IExtractSingleOptions<T>,
): T => {
  const data = entities[type] as { [id: string]: T } | undefined;

  const { errorMessage, filter } = options ?? {};

  if (!data) {
    throw new Error(errorMessage ? errorMessage : "An error occured");
  }

  const result = data[results[type][0]];

  if (filter && !filter(result)) {
    throw new EntityNotFoundError();
  }

  return result;
};

export interface IExtractOptions<T> {
  comparableAttributeExtrator?: (data: T) => number;
  typeToMerge?: EntityTypes;
  errorMessage?: string;
  filter?: (data: T) => boolean;
  sort?: (a: T, b: T) => number;
}

export const extractMultiple = <T extends Entity>(
  type: EntityTypes,
  { entities }: NormalizedAPIResponse<EntitiesAttributes>,
  options?: IExtractOptions<T>,
): T[] => {
  const data = entities[type] as { [id: string]: T } | undefined;

  const { comparableAttributeExtrator, typeToMerge, errorMessage, filter, sort } = options ?? {};

  if (!data) {
    if (!errorMessage) return [];
    throw new Error(errorMessage);
  }

  let dataList: T[] = Object.values(data);

  // Merge entities.
  if (typeToMerge) {
    dataList = mergeEntities(dataList, entities, typeToMerge);
  }

  // Sort entities.
  if (comparableAttributeExtrator) {
    dataList.sort((a, b) => comparableAttributeExtrator(a) - comparableAttributeExtrator(b));
  } else {
    dataList = (sort && dataList.sort(sort)) ?? dataList;
  }

  return dataList.filter((d) => (filter ? filter(d) : true));
};
