import { useTranslation } from "next-i18next";
import { FunctionComponent, useState } from "react";

import styles from "./EvaluationLink.module.scss";

import AskEvaluationModal from "@components/brand/askEvaluationModal";
import APICallManager from "@components/generic/apiCallManager";
import ArrowLink from "@components/generic/arrowLink";
import CTAButton from "@components/generic/ctaButton";
import ErrorMessage from "@components/generic/ErrorMessage";
import Modal from "@components/generic/modal/modal";
import SearchLoader from "@components/generic/search/searchLoader";
import { DataLayer, GTM } from "@lib/gtm";
import { EMPTY } from "@lib/helper/stringUtils";
import { incrementBrandCounter } from "@lib/store/brands";
import { IBrandCounter } from "@lib/store/models/brandsModels";

type EvaluationLinkType = "arrow" | "cta";

export interface IEvaluationLinkProps {
  userEntry: string;
  className?: string;
  type?: EvaluationLinkType;
}

const EvaluationLink: FunctionComponent<IEvaluationLinkProps> = ({ userEntry, className, type = "arrow" }) => {
  const [showModal, setShowModal] = useState(false);

  const { t } = useTranslation(["common", "error"]);

  const isArrow = type === "arrow";

  const _onClose = () => {
    setShowModal(false);
  };

  const _onClick = () => {
    const trackingEvent: DataLayer = {
      event: "click_evalBrand",
      userEntry,
    };

    GTM.trackEvent(trackingEvent);

    if (!showModal) setShowModal(true);
  };

  const _renderLoader = (): JSX.Element => {
    return <SearchLoader className={styles["evaluation-link__loading"]} />;
  };

  const _renderError = (message: string, onRetry?: () => void): JSX.Element => {
    return <ErrorMessage message={message} onRetry={onRetry} />;
  };

  const _renderSuccess = (data: IBrandCounter): JSX.Element => {
    const { name, score } = data;

    return <AskEvaluationModal name={name} score={score} onClose={_onClose} />;
  };

  return (
    <>
      {isArrow ? (
        <ArrowLink onClick={_onClick} className={className}>
          <p className={styles["evaluation-link__succeeded"]} id={"cta-eval-brand-search-succeeded"}>
            {t("search.askEvaluationVariable", { input: userEntry })}
          </p>
        </ArrowLink>
      ) : (
        <CTAButton onClick={_onClick} className={className} id={"cta-eval-brand-search-failed"}>
          {t("search.askEvaluation")}
        </CTAButton>
      )}
      <Modal onClose={_onClose} show={showModal} title={EMPTY} className={styles["evaluation-link__modal"]}>
        <APICallManager
          callAPI={() => incrementBrandCounter(userEntry)}
          renderLoader={_renderLoader}
          renderError={_renderError}
        >
          {_renderSuccess}
        </APICallManager>
      </Modal>
    </>
  );
};

export default EvaluationLink;
