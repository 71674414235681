import { AxiosPromise } from "axios";

import { APIContext, APIData } from "../apiData";

import {
  CLEAR_FASHION_API_INSTANCE,
  CLEAR_FASHION_WEB_API_INSTANCE,
  DEFAULT_LOCALE,
} from "@lib/api/clear_fashion/apiConfig";
import { APIResponse } from "@lib/api/clear_fashion/types";
import { ThemeCode } from "@lib/helper/themeUtils";
import {
  BRAND_CRITERIUM_NODE_TYPE,
  BRAND_SECTION_NODE_TYPE,
  BRAND_SUBCRITERIUM_NODE_TYPE,
  IBrandCriteriumNode,
  IBrandSectionNode,
  IBrandSubcriteriumNode,
} from "@lib/store/models/brandsModels";

export interface IBrandFromWebApiOptions {
  locale?: string;
  lightVersion?: boolean;
}

export function getBrandsFromWebApi(options?: IBrandFromWebApiOptions): AxiosPromise<APIResponse> {
  const { locale, lightVersion } = options ?? { locale: DEFAULT_LOCALE, lightVersion: false };

  const params = {
    locale: locale,
    light: lightVersion,
  };
  return CLEAR_FASHION_WEB_API_INSTANCE.get("brands", { params });
}

export function getBrandFromWebAPI(brand: string, locale: string = DEFAULT_LOCALE): AxiosPromise<APIResponse> {
  const params = {
    locale: locale,
  };
  return CLEAR_FASHION_WEB_API_INSTANCE.get(`brands/${brand}`, { params });
}

export function incrementBrandCounterViaApi(brand: string): AxiosPromise<APIResponse> {
  const params = {
    name: brand,
  };
  return CLEAR_FASHION_API_INSTANCE.put("brand_counters/increment", params);
}

export const getBrandSectionsFromApi = (
  context: APIContext,
  brandCode: string,
  theme: ThemeCode,
): APIData<IBrandSectionNode> => {
  return APIData.get(`brands/${brandCode}/themes/${theme}/sections`, context, {
    type: BRAND_SECTION_NODE_TYPE,
  });
};

export const getBrandCriteriumsFromApi = (
  context: APIContext,
  brandCode: string,
  sectionId: string,
): APIData<IBrandCriteriumNode> => {
  return APIData.get(`brands/${brandCode}/sections/${sectionId}/criteriums`, context, {
    type: BRAND_CRITERIUM_NODE_TYPE,
  });
};

export const getBrandSubcriteriumsFromApi = (
  context: APIContext,
  brandCode: string,
  criteriumId: string,
): APIData<IBrandSubcriteriumNode> => {
  return APIData.get(`brands/${brandCode}/criteriums/${criteriumId}/subcriteriums`, context, {
    type: BRAND_SUBCRITERIUM_NODE_TYPE,
  });
};
