import { indexSignatureFromArrayValues } from "@tevari/helpers";
import { AxiosError, AxiosProgressEvent, AxiosPromise, AxiosRequestConfig, AxiosResponse } from "axios";

import { CLEAR_FASHION_WEB_API_INSTANCE } from "./clear_fashion/apiConfig";
import { APIResponse, EntitiesWithMeta } from "./clear_fashion/types";
import { APIError, NoContentError, extractError } from "../store/apiErrors";

import { EMPTY, camelCaseToSnakeCase } from "@lib/helper/stringUtils";
import {
  Entity,
  EntityTypes,
  IExtractOptions,
  extractMultiple,
  extractSingle,
  getNormalizedData,
} from "@lib/store/normalizer";

export interface APIContext {
  getIdToken?: () => Promise<string>;
  locale: string;
}

export class APIData<T extends Entity> {
  private readonly promise: AxiosPromise<APIResponse>;
  private readonly type?: EntityTypes;

  private constructor(promise: AxiosPromise<APIResponse>, type?: EntityTypes) {
    this.promise = promise
      .then((response) => {
        if (response.status === 204) throw new NoContentError();
        return response;
      })
      .catch((error: AxiosError) => {
        throw extractError(error);
      });
    this.type = type;
  }

  private _asArray = async (promise: AxiosPromise<APIResponse>, options?: IExtractOptions<T>): Promise<T[]> => {
    const type = this.type;
    if (type === undefined) throw new APIError();

    return promise.then((response) => extractMultiple<T>(type, getNormalizedData(response), options));
  };

  private _asArrayWithMeta = async (
    promise: AxiosPromise<APIResponse>,
    options?: IExtractOptions<T>,
  ): Promise<EntitiesWithMeta<T>> => {
    const type = this.type;
    if (type === undefined) throw new APIError();

    return promise.then((response) => {
      if (!response.data.meta) throw Error("Meta data was not set on response");

      return {
        meta: response.data.meta,
        data: extractMultiple<T>(type, getNormalizedData(response), options),
      };
    });
  };

  private _asSingle = async (promise: AxiosPromise<APIResponse>, options?: IExtractOptions<T>): Promise<T> => {
    const type = this.type;
    if (type === undefined) throw new APIError();

    return promise.then((response) => extractSingle<T>(type, getNormalizedData(response), options));
  };

  private _asVoid = async (promise: AxiosPromise<APIResponse>): Promise<void> => {
    return promise.then(() => {
      return;
    });
  };

  private _count = async (promise: AxiosPromise<APIResponse>): Promise<number> => {
    return promise.then((response) => {
      if (response.data.count !== undefined) return response.data.count;
      if (response.data.meta === undefined) throw new APIError();
      return response.data.meta.count;
    });
  };

  private get() {
    return this.promise;
  }

  public asArray(options?: IExtractOptions<T>) {
    return this._asArray(this.get(), options);
  }

  public asArrayWithMeta(options?: IExtractOptions<T>) {
    return this._asArrayWithMeta(this.get(), options);
  }

  public asSingle(options?: IExtractOptions<T>) {
    return this._asSingle(this.get(), options);
  }

  public asVoid() {
    return this._asVoid(this.get());
  }

  public count() {
    return this._count(this.get());
  }

  public asAggregation() {
    return this._count(this.get());
  }

  public static convertFilters(filters?: APIFilter[]) {
    if (!filters || filters.length === 0) return EMPTY;
    return indexSignatureFromArrayValues(
      filters,
      ({ attribute }) => camelCaseToSnakeCase(attribute),
      ({ value }) => value,
    );
  }

  private static create<T extends Entity>(
    apiContext: APIContext,
    requestCallback: (parameters: {
      request?: AxiosRequestConfig<unknown>;
      data?: unknown;
      formData?: FormData;
    }) => Promise<AxiosResponse>,
    options?: IAPIRequestOptions,
  ): APIData<T> {
    const { locale, getIdToken } = apiContext;
    const { type, pagination = {}, parameters = {}, filters, body, formData, onUploadProgress } = options ?? {};

    const _request = (idToken?: string) => {
      const request = {
        headers: {
          ...(idToken && { authorization: idToken }),
          ...(formData && { "Content-Type": "multipart/form-data" }),
        },
        ...((pagination || parameters || filters || locale) && {
          params: { locale, ...pagination, ...parameters, ...APIData.convertFilters(filters) },
        }),
        ...(onUploadProgress && { onUploadProgress }),
      };
      return requestCallback({ request, data: formData ?? body, formData });
    };

    if (!getIdToken) return new APIData<T>(_request(), type);

    return new APIData<T>(getIdToken().then(_request), type);
  }

  public static get<T extends Entity>(url: string, apiContext: APIContext, options: IAPIRequestOptions): APIData<T> {
    return APIData.create(apiContext, ({ request }) => CLEAR_FASHION_WEB_API_INSTANCE.get(url, request), options);
  }

  public static post<T extends Entity>(url: string, apiContext: APIContext, options: IAPIRequestOptions): APIData<T> {
    return APIData.create(
      apiContext,
      ({ request, data }) => CLEAR_FASHION_WEB_API_INSTANCE.post(url, data, request),
      options,
    );
  }

  public static put<T extends Entity>(url: string, apiContext: APIContext, options: IAPIRequestOptions): APIData<T> {
    return APIData.create(
      apiContext,
      ({ request, data }) => CLEAR_FASHION_WEB_API_INSTANCE.put(url, data, request),
      options,
    );
  }

  public static patch<T extends Entity>(url: string, apiContext: APIContext, options: IAPIRequestOptions): APIData<T> {
    return APIData.create(
      apiContext,
      ({ request, data }) => CLEAR_FASHION_WEB_API_INSTANCE.patch(url, data, request),
      options,
    );
  }

  public static delete<T extends Entity>(url: string, apiContext: APIContext, options: IAPIRequestOptions): APIData<T> {
    return APIData.create(apiContext, ({ request }) => CLEAR_FASHION_WEB_API_INSTANCE.delete(url, request), options);
  }
}

export interface APIPagination {
  page?: number;
  perPage?: number;
}

export interface APIFilter {
  attribute: string;
  value: string;
}

export interface IAPIRequestOptions {
  type?: EntityTypes;
  pagination?: APIPagination;
  filters?: APIFilter[];
  body?: unknown;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parameters?: any;
  formData?: FormData;
  onUploadProgress?: (event: AxiosProgressEvent) => void;
}
