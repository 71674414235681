import router from "next/router";
import { i18n } from "next-i18next";
import { FunctionComponent, useState } from "react";

import { IOption } from "@components/generic/dropdown";
import Dropdown from "@components/generic/dropdown";

export const LANG_OPTIONS: IOption[] = [
  { value: "fr", label: "Français", shortLabel: "FR" },
  { value: "en", label: "English", shortLabel: "EN" },
];

interface ILangSelectorProps {
  className?: string;
  isDarkMode?: boolean;
}

export const DEFAULT_LANGUAGE_BACKUP = "fr";

const LangSelector: FunctionComponent<ILangSelectorProps> = ({ className, isDarkMode }) => {
  const [locale, setLocale] = useState(i18n ? i18n.language : DEFAULT_LANGUAGE_BACKUP);

  const _switchLocal = (value: string) => {
    setLocale(value);
    const { pathname, asPath, query } = router;

    router.push({ pathname, query }, asPath, { locale: value });
  };

  return (
    <Dropdown
      title={locale.toLocaleUpperCase()}
      options={LANG_OPTIONS}
      selectedItemCallback={_switchLocal}
      className={className}
      isDarkMode={isDarkMode}
    />
  );
};

export default LangSelector;
