import { AxiosError } from "axios";

import { ITheme } from "./models/themesModel";

import { APIContext } from "@lib/api/apiData";
import { EntityNotFoundError, extractError } from "@lib/api/apiErrors";
import {
  getBrandCriteriumsFromApi,
  getBrandFromWebAPI,
  getBrandSectionsFromApi,
  getBrandsFromWebApi,
  getBrandSubcriteriumsFromApi,
  incrementBrandCounterViaApi,
} from "@lib/api/clear_fashion/brands";
import { eBrandStatus } from "@lib/helper/brandUtils";
import { compareNatural, isAlpha, isBlank, plainifyString } from "@lib/helper/stringUtils";
import { ThemeCode } from "@lib/helper/themeUtils";
import {
  BRAND_COUNTER_TYPE,
  BRAND_CRITERIUM_NODE_TYPE,
  BRAND_ORIGIN_TYPE,
  BRAND_SECTION_NODE_TYPE,
  BRAND_SOURCE_TYPE,
  BRAND_SUBCRITERIUM_NODE_TYPE,
  BRAND_THEME_NODE_TYPE,
  BRAND_TYPE,
  CRITERIUM_NODE_TYPE,
  GENDER_TYPE,
  IAllBrandsInfo,
  IBrand,
  IBrandCounter,
  IBrandCriteriumNode,
  IBrandData,
  IBrandOrigin,
  IBrandSectionNode,
  IBrandSource,
  IBrandSubcriteriumNode,
  IGender,
  IProductCategory,
  PRODUCT_CATEGORY_TYPE,
  SECTION_NODE_TYPE,
  SUBCRITERIUM_NODE_TYPE,
  THEME_NODE_TYPE,
} from "@lib/store/models/brandsModels";
import {
  EntitiesAttributes,
  Entity,
  extractMultiple,
  extractSingle,
  getNormalizedData,
  NormalizedAPIResponse,
} from "@lib/store/normalizer";
import { IAllBrandsParams } from "@pages/all-brands/[[...index]]";
import { IBrandParams } from "@pages/brands/[code]/index";

export const NOT_DEREFERENCED_FILTER = (brand: IBrand) =>
  brand.status !== eBrandStatus.Dereferenced && brand.status !== eBrandStatus.Draft;
export const SPECIAL_CHARACTERS_INDEX = "others";
export const SPECIAL_CHARACTERS_LABEL = "& 0-9";

export const getAllBrandCodes = (): Promise<{ params: IBrandParams }[]> => {
  return getBrandsFromWebApi({ lightVersion: true })
    .then((response) => {
      const brandEntities = extractMultiple<IBrand>(BRAND_TYPE, getNormalizedData(response), {
        filter: NOT_DEREFERENCED_FILTER,
      });

      return brandEntities.map((brand) => {
        return { params: { code: brand.code } };
      });
    })
    .catch((error: AxiosError) => {
      throw extractError(error);
    });
};

function getBrandsByIndex(brands: IBrand[]): { [id: string]: IBrand[] } {
  const brandsByIndex: { [id: string]: IBrand[] } = {};

  for (const brand of brands) {
    const firstCharacter = plainifyString(brand.name.charAt(0).toLowerCase());
    const index = isAlpha(firstCharacter) ? firstCharacter : SPECIAL_CHARACTERS_INDEX;
    if (!(index in brandsByIndex)) {
      brandsByIndex[index] = [];
    }
    brandsByIndex[index].push(brand);
  }
  return brandsByIndex;
}

export const getAllBrandIndexes = (): Promise<{ params: IAllBrandsParams }[]> => {
  return getBrandsFromWebApi({ lightVersion: true })
    .then((response) => {
      const brandEntities = extractMultiple<IBrand>(BRAND_TYPE, getNormalizedData(response), {
        filter: NOT_DEREFERENCED_FILTER,
      });

      if (brandEntities.length === 0) return [];

      const brandsByIndex = getBrandsByIndex(brandEntities);

      const indexParams = Object.keys(brandsByIndex).map((index) => {
        return { params: { index: [index] } };
      });

      // To catch the route without any index (`/all-brands/`)
      indexParams.push({ params: { index: [] } });

      return indexParams;
    })
    .catch((error: AxiosError) => {
      throw extractError(error);
    });
};

export const getAllBrandsInfo = (index: string, locale?: string): Promise<IAllBrandsInfo> => {
  return getAllBrands({ locale }).then((brands) => {
    const brandsByIndex = getBrandsByIndex(brands);

    return {
      brands: brandsByIndex[index].sort((a, b) => compareNatural(a.name, b.name)),
      indexes: Object.keys(brandsByIndex),
    };
  });
};

export interface IGetAllBrandOptions {
  locale?: string;
  lightVersion?: boolean;
}

export const getAllBrands = (options?: IGetAllBrandOptions): Promise<IBrand[]> => {
  return getBrandsFromWebApi(options ?? { lightVersion: false })
    .then((response) => {
      return extractMultiple<IBrand>(BRAND_TYPE, getNormalizedData(response), { filter: NOT_DEREFERENCED_FILTER });
    })
    .catch((error: AxiosError) => {
      throw extractError(error);
    });
};

export const incrementBrandCounter = (brand: string): Promise<IBrandCounter> => {
  return incrementBrandCounterViaApi(brand)
    .then((response) => {
      return extractSingle<IBrandCounter>(BRAND_COUNTER_TYPE, getNormalizedData(response), {
        errorMessage: `An error occured while incrementing the brand counter for '${brand}'`,
      });
    })
    .catch((error: AxiosError) => {
      throw extractError(error);
    });
};

function groupById<T extends Entity>(entities: T[], idExtractor: (entity: T) => string) {
  const groupedEntities: { [id: string]: T[] } = {};
  for (const entity of entities) {
    const id = idExtractor(entity);
    if (!isBlank(id)) {
      if (groupedEntities[id] === undefined) {
        groupedEntities[id] = [];
      }
      groupedEntities[id].push(entity);
    }
  }
  return groupedEntities;
}

const extractBrand = (normalizedData: NormalizedAPIResponse<EntitiesAttributes>) => {
  return extractSingle<IBrand>(BRAND_TYPE, normalizedData, {
    errorMessage: "No brand found",
    filter: NOT_DEREFERENCED_FILTER,
  });
};

const extractThemes = (normalizedData: NormalizedAPIResponse<EntitiesAttributes>) => {
  return extractMultiple<ITheme>(BRAND_THEME_NODE_TYPE, normalizedData, {
    comparableAttributeExtrator: (data) => data.order,
    typeToMerge: THEME_NODE_TYPE,
  });
};

const extractSections = (normalizedData: NormalizedAPIResponse<EntitiesAttributes>) => {
  const sections = extractMultiple<IBrandSectionNode>(BRAND_SECTION_NODE_TYPE, normalizedData, {
    comparableAttributeExtrator: (data) => data.order,
    typeToMerge: SECTION_NODE_TYPE,
  });
  return groupById(sections, (e) => e.themeCode);
};

const extractCriteriums = (normalizedData: NormalizedAPIResponse<EntitiesAttributes>) => {
  const criteriums = extractMultiple<IBrandCriteriumNode>(BRAND_CRITERIUM_NODE_TYPE, normalizedData, {
    comparableAttributeExtrator: (data) => data.order,
    typeToMerge: CRITERIUM_NODE_TYPE,
  });
  return groupById(criteriums, (e) => e.brandSectionNodeId);
};

const extractSubcriteriums = (normalizedData: NormalizedAPIResponse<EntitiesAttributes>) => {
  const subcriteriums = extractMultiple<IBrandSubcriteriumNode>(BRAND_SUBCRITERIUM_NODE_TYPE, normalizedData, {
    comparableAttributeExtrator: (data) => data.order,
    typeToMerge: SUBCRITERIUM_NODE_TYPE,
  });
  return groupById(subcriteriums, (e) => e.brandCriteriumNodeId);
};

const extractBrandSources = (normalizedData: NormalizedAPIResponse<EntitiesAttributes>) => {
  const brandSources = extractMultiple<IBrandSource>(BRAND_SOURCE_TYPE, normalizedData);
  return groupById(brandSources, (bs) => bs.brandCriteriumNodeId);
};

const extractGenders = (normalizedData: NormalizedAPIResponse<EntitiesAttributes>) => {
  return extractMultiple<IGender>(GENDER_TYPE, normalizedData, { comparableAttributeExtrator: (data) => data.order });
};

const extractProductCategories = (normalizedData: NormalizedAPIResponse<EntitiesAttributes>) => {
  return extractMultiple<IProductCategory>(PRODUCT_CATEGORY_TYPE, normalizedData, {
    comparableAttributeExtrator: (data) => data.order,
  });
};

const extractBrandOrigins = (normalizedData: NormalizedAPIResponse<EntitiesAttributes>) => {
  return extractMultiple<IBrandOrigin>(BRAND_ORIGIN_TYPE, normalizedData);
};

export const getBrand = (brand: string, locale?: string): Promise<IBrandData> => {
  return getBrandFromWebAPI(brand, locale)
    .then((response) => {
      const normalizedData: NormalizedAPIResponse<EntitiesAttributes> = getNormalizedData<EntitiesAttributes>(response);

      if (!normalizedData.entities[BRAND_TYPE]) {
        throw new EntityNotFoundError();
      }

      const brandEntity = extractBrand(normalizedData);

      if ([eBrandStatus.Dereferenced, eBrandStatus.Draft].includes(brandEntity.status)) {
        throw new EntityNotFoundError();
      }

      const themes = extractThemes(normalizedData);
      const sectionsByThemeId = extractSections(normalizedData);
      const criteriumsBySectionId = extractCriteriums(normalizedData);
      const subcriteriumsByCriteriumId = extractSubcriteriums(normalizedData);
      const brandSourcesByCriteriumId = extractBrandSources(normalizedData);
      const genders = extractGenders(normalizedData);
      const productCategories = extractProductCategories(normalizedData);
      const origins = extractBrandOrigins(normalizedData);

      return {
        brand: brandEntity,
        themes,
        sectionsByThemeId,
        criteriumsBySectionId,
        subcriteriumsByCriteriumId,
        brandSourcesByCriteriumId,
        genders,
        productCategories,
        origins,
      };
    })
    .catch((error: AxiosError) => {
      throw extractError(error);
    });
};

export const getBrandSections = (
  context: APIContext,
  brandCode: string,
  themeCode: ThemeCode,
): Promise<IBrandSectionNode[]> => {
  return getBrandSectionsFromApi(context, brandCode, themeCode).asArray();
};

export const getBrandCriteriums = (
  context: APIContext,
  brandCode: string,
  sectionId: string,
): Promise<IBrandCriteriumNode[]> => {
  return getBrandCriteriumsFromApi(context, brandCode, sectionId).asArray();
};

export const getBrandSubcriteriums = (
  context: APIContext,
  brandCode: string,
  criteriumId: string,
): Promise<IBrandSubcriteriumNode[]> => {
  return getBrandSubcriteriumsFromApi(context, brandCode, criteriumId).asArray();
};
