import { useCallback } from "react";
import React from "react";

import { isEmpty } from "@lib/helper/stringUtils";
import { useInitialFetch } from "hooks/useInitialProps";

interface IAPICallManagerProps<T> {
  callAPI(): Promise<T>;
  renderLoader(): JSX.Element;
  renderError(message: string, onRetry?: () => void): JSX.Element;
  children(data: T): JSX.Element;
  className?: string;
}

// eslint-disable-next-line @typescript-eslint/ban-types
const APICallManager = <T extends {}>({
  callAPI,
  renderLoader,
  renderError,
  children,
  className,
}: IAPICallManagerProps<T>) => {
  const [loading, errorMessage, data, onRetry] = useInitialFetch(callAPI);

  const _renderContent = useCallback((): JSX.Element => {
    if (loading) return renderLoader();
    if (!isEmpty(errorMessage)) return renderError(errorMessage, onRetry);
    if (data == undefined) return <></>;

    return <>{children(data)}</>;
  }, [loading, renderLoader, errorMessage, renderError, onRetry, data, children]);

  return <div className={className}>{_renderContent()}</div>;
};

export default APICallManager;
