export const badgeImages = {
  fr: {
    appStore: "/images/badges/app-store-badge-fr.svg",
    googlePlay: "/images/badges/google-play-badge-fr.svg",
  },
  en: {
    appStore: "/images/badges/app-store-badge-en.svg",
    googlePlay: "/images/badges/google-play-badge-en.svg",
  },
};

export const logoImages = {
  logoWithText: "/images/logos/logo-with-text.svg",
  logoWithWhiteText: "/images/logos/logo-with-white-text.svg",
  brandLogoPlaceholder: "/images/logos/brand/brand-logo-placeholder.svg",
  brandCoverPlaceholder: "/images/logos/brand/brand-cover-placeholder.svg",
};

export const homePageImages = {
  leftVisual: "/images/home/left-home-visual.webp",
  rightVisual: "/images/home/right-home-visual.webp",
  methodologyEn: "/images/home/clear-fashion-methodology-en.webp",
  methodologyFr: "/images/home/clear-fashion-methodology-fr.webp",
  mobileAppVisual: "/images/home/mobile-app-visual.webp",
  fancyArrow: "/images/home/fancy-arrow.svg",
};

export const symbolImages = {
  yes: "/images/symbols/yes.svg",
  no: "/images/symbols/no.svg",
  yesButNotAll: "/images/symbols/partially.svg",
  yesButNotAllOver60: "/images/symbols/partially-over-60.svg",
  doNotKnow: "/images/symbols/unknown.svg",
};

export const themeImages = {
  environment: "/images/themes/environment.svg",
  human: "/images/themes/human.svg",
  health: "/images/themes/health.svg",
  animal: "/images/themes/animal.svg",
};

export const productionOriginImages = {
  main: "/images/production-origin/production-origin.svg",
  chart: "/images/production-origin/origin-chart.svg",
  country: "/images/production-origin/country-location.svg",
};

export const trafficLightImages = {
  colorless: "/images/trafficlights/colorless.svg",
};

export const participationImages = {
  fsParticipating: "/images/participations/fs-participating.svg",
  inProgress: "/images/participations/in-progress.svg",
  notParticipating: "/images/participations/not-participating.svg",
  participating: "/images/participations/participating.svg",
};

export const starImages = {
  greyStar: "/images/stars/star-grey.svg",
  emptyStar: "/images/stars/star-empty.svg",
  halfFilledStar: "/images/stars/star-half-filled.svg",
  filledStar: "/images/stars/star-filled.svg",
};

export const commonImages = {
  arrow: "/images/common/arrow.svg",
  chevron: "/images/common/chevron.svg",
  close: "/images/common/close.svg",
  dropdown: "/images/common/dropdown.svg",
  tag: "/images/common/tag.svg",
};

export const reliabilityImages = {
  reliable: "/images/reliability/reliable.svg",
  waiting: "/images/reliability/waiting.svg",
  warning: "/images/reliability/warning.svg",
};

export const searchbarImages = {
  search: "/images/searchbar/search.svg",
  loader: "/images/searchbar/loader.gif",
};

export const onboardingImages = {
  stepSuccess: "/images/onboarding/step-3.svg",
};

export const custom404Images = {
  logo: "/images/404/logo.svg",
};

export const productionStepImages = {
  rawMaterial: "/images/production-steps/rawMaterial.svg",
  spinning: "/images/production-steps/spinning.svg",
  weaving: "/images/production-steps/weaving.svg",
  dyeingAndPrinting: "/images/production-steps/dyeingAndPrinting.svg",
  manufacturing: "/images/production-steps/manufacturing.svg",
  stitching: "/images/production-steps/stitching.svg",
  assembly: "/images/production-steps/assembly.svg",
  finishing: "/images/production-steps/finishing.svg",
};

export const materialImpactImages = {
  recycled: "/images/material-impacts/recycled.svg",
  microFiber: "/images/material-impacts/microFiber.svg",
  risk: "/images/material-impacts/risk.svg",
};
