import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { FunctionComponent } from "react";

import styles from "./Header.module.scss";

import BrandSearchBar from "@components/brand/brandSearchBar";
import LangSelector from "@components/header/langSelector";
import { getPrevInHierarchy, getRootPath } from "@lib/helper/pathUtils";
import { commonImages, logoImages } from "@lib/images";

interface IHeaderProps {
  className?: string;
  forceLightMode?: boolean;
  withoutConnections?: boolean;
}

const Header: FunctionComponent<IHeaderProps> = ({ className, forceLightMode = false, withoutConnections = false }) => {
  const router = useRouter();
  const isHome = router.pathname == getRootPath();
  const isDarkMode = !forceLightMode && isHome;
  const searchBarClassName = isDarkMode ? `${styles["header__search"]} ${styles["home"]}` : styles["header__search"];

  const _renderLogo = () => (
    <Image
      src={isDarkMode ? logoImages.logoWithWhiteText : logoImages.logoWithText}
      alt={"Clear Fashion Logo"}
      width={239}
      height={40}
      priority={true}
      sizes="100vw"
      style={{
        width: "100%",
        height: "auto",
      }}
    />
  );

  return (
    <header className={`${styles["header"]} ${className}`}>
      <div className={styles["header__container"]}>
        {isHome || withoutConnections ? (
          <div className={`${styles["header__back-button"]} ${styles["header__back-button--hidden"]}`}></div>
        ) : (
          <button
            className={styles["header__back-button"]}
            id={"cta-all-brands-mobile-header"}
            onClick={() => router.push(getPrevInHierarchy(router.asPath))}
          >
            <Image
              src={commonImages.chevron}
              alt="chevron"
              height={16}
              width={9}
              priority={true}
              sizes="100vw"
              style={{
                width: "100%",
                height: "auto",
              }}
            />
          </button>
        )}
        {withoutConnections ? (
          <div className={styles["header__logo"]}>{_renderLogo()}</div>
        ) : (
          <Link href="/" className={styles["header__logo"]}>
            {_renderLogo()}
          </Link>
        )}
        {withoutConnections ? (
          <div className={searchBarClassName}></div>
        ) : (
          <BrandSearchBar className={searchBarClassName} declaredInHeader />
        )}
        <LangSelector className={styles["header__lang-selector"]} isDarkMode={isDarkMode} />
      </div>
    </header>
  );
};

export default Header;
