import axios, { InternalAxiosRequestConfig } from "axios";
import { decamelizeKeys } from "humps";

const snakeCaseConvertorMiddleware = (
  config: InternalAxiosRequestConfig<unknown>,
): InternalAxiosRequestConfig<unknown> => {
  const newConfig = { ...config };

  if (newConfig.headers && newConfig.headers["Content-Type"] === "multipart/form-data") return newConfig;
  if (config.params) newConfig.params = decamelizeKeys(config.params);
  if (config.data) newConfig.data = decamelizeKeys(config.data);
  return newConfig;
};

export const CLEAR_FASHION_API_INSTANCE = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
  timeout: 120000,
});

CLEAR_FASHION_API_INSTANCE.interceptors.request.use(snakeCaseConvertorMiddleware);

export const CLEAR_FASHION_WEB_API_INSTANCE = axios.create({
  baseURL: process.env.NEXT_PUBLIC_WEB_API_BASE_URL,
  timeout: 120000,
});

CLEAR_FASHION_WEB_API_INSTANCE.interceptors.request.use(snakeCaseConvertorMiddleware);

export const DEFAULT_LOCALE = "fr";
