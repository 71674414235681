import { EMPTY } from "@lib/helper/stringUtils";
import { participationImages } from "@lib/images";

export enum eBrandStatus {
  NonParticipating = "non_participating",
  Participating = "participating",
  Dereferenced = "dereferenced",
  ParticipatingInProcess = "participating_in_process",
  Draft = "draft",
}

export const isBrandDereferenced = (status?: eBrandStatus): boolean => {
  if (status === undefined) return false;

  return status === eBrandStatus.Dereferenced;
};

export const getBrandStatusIcon = (brandStatus: eBrandStatus, hasFashionScore: boolean): string => {
  switch (brandStatus) {
    case eBrandStatus.Participating:
      return hasFashionScore ? participationImages.fsParticipating : participationImages.participating;
    case eBrandStatus.ParticipatingInProcess:
      return participationImages.inProgress;
    case eBrandStatus.NonParticipating:
      return participationImages.notParticipating;
    case eBrandStatus.Dereferenced:
      return participationImages.notParticipating;
    default:
      return participationImages.notParticipating;
  }
};

export const getBrandStatusTitle = (brandStatus: eBrandStatus, hasFashionScore: boolean): string => {
  switch (brandStatus) {
    case eBrandStatus.Participating:
      return hasFashionScore ? "brandParticipation.fsParticipation.title" : "brandParticipation.participation.title";
    case eBrandStatus.ParticipatingInProcess:
      return "brandParticipation.participationInProgress.title";
    case eBrandStatus.NonParticipating:
      return "brandParticipation.noParticipation.title";
    default:
      return "brandParticipation.noParticipation.title";
  }
};

export const getBrandStatusExplanationsBaseKey = (brandStatus: eBrandStatus, hasFashionScore: boolean): string => {
  switch (brandStatus) {
    case eBrandStatus.Participating:
      return hasFashionScore ? "brandParticipation.fsParticipation" : "brandParticipation.participation";
    case eBrandStatus.ParticipatingInProcess:
      return "brandParticipation.participationInProgress";
    case eBrandStatus.NonParticipating:
      return "brandParticipation.noParticipation";
    default:
      return "brandParticipation.noParticipation";
  }
};

export type RangePrice = "very_low" | "low" | "medium" | "high" | "very_high";

const RangePriceText = {
  very_low: "€",
  low: "€€",
  medium: "€€",
  high: "€€€",
  very_high: "€€€€",
};

export function getRangePriceText(rangePrice?: RangePrice): string {
  if (!rangePrice) return EMPTY;
  return RangePriceText[rangePrice] || EMPTY;
}

export type Gender = "female" | "child" | "male";
