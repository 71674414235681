"use client";

import Image from "next/image";
import React, { useCallback } from "react";
import { ReactNode, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

import styles from "./Modal.module.scss";

import { commonImages } from "@lib/images";

export interface IModalProps {
  show: boolean;
  onClose(): void;
  title?: string;
  children?: ReactNode;
  className?: string;
}

export const MODAL_ROOT_ID = "modal-root";

export const isModalOpened = (): boolean => {
  const hasChildNodes = document.getElementById(MODAL_ROOT_ID)?.hasChildNodes();
  return hasChildNodes ? hasChildNodes : false;
};

const Modal = ({ show, onClose, children, title, className }: IModalProps) => {
  const [isBrowser, setIsBrowser] = useState(false);

  const modalWrapperRef = useRef<null | HTMLDivElement>(null);

  const _handleCloseClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      onClose();
    },
    [onClose],
  );

  const _backDropHandler = useCallback(
    (e: MouseEvent) => {
      if (!modalWrapperRef?.current?.contains(e.target as Node)) {
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    setIsBrowser(true);
    window.addEventListener("click", _backDropHandler, true);
    return () => window.removeEventListener("click", _backDropHandler);
  }, [_backDropHandler]);

  const modalContent = show ? (
    <div className={`${styles["modal"]} ${className}`}>
      <div className={styles["modal__wrapper"]} ref={modalWrapperRef}>
        <div className={styles["modal__content"]}>
          <div className={styles["modal__header"]}>
            <div className={styles["modal__close"]} onClick={_handleCloseClick}>
              <Image
                src={commonImages.close}
                alt={"Close button"}
                width={20}
                height={20}
                style={{
                  maxWidth: "100%",
                  height: "auto",
                }}
              />
            </div>
            {title && <h2 className={styles["modal__title"]}>{title}</h2>}
          </div>
          <div className={styles["modal__body"]}>{children}</div>
        </div>
      </div>
    </div>
  ) : null;

  if (isBrowser) {
    const modalElement = document.getElementById(MODAL_ROOT_ID);
    if (!modalElement) return null;

    return createPortal(modalContent, modalElement);
  } else {
    return null;
  }
};

export default Modal;
